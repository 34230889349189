import './styles.css'
import { Button1 } from "../../../components/button/button1"
import { useHistory } from "../../../hooks/useHistory"
import Switch from '@mui/material/Switch';
// images
import Image1 from '../../../assets/img2.jpg'

export const Rent = () => {
    const { goBack } = useHistory()
    return (
        <div className="rent-page-container page">
            <div className="rent-page-wrapper">
                <div className="rent-page-head">
                    <div className="rent-page-head-text">
                        <h3>Rent Details</h3>
                    </div>
                        <Button1 action={goBack} backgroundColor='lightgray' color='black' text='Back'/>
                </div>
                <div className="rent-details-container">
                    <div className="rent-details-text">
                        <h2>The Fun House</h2>
                    </div>
                    <div className="rent-details-image">
                        <div className="rent-details-image-box">
                            <img src={Image1} alt="" />
                        </div>
                        <div className="rent-details-image-box">
                            <img src={Image1} alt="" />
                        </div>
                        <div className="rent-details-image-box">
                            <img src={Image1} alt="" />
                        </div>
                        <div className="rent-details-image-box">
                            <img src={Image1} alt="" />
                        </div>
                    </div>
                    <div className="rent-details-sub-container">
                    <div className='account-info-box2'>
                        <div className='account-info-box-head account-info-box-wrapper'>Actions</div>
                        <hr className='line'/>
                        <div className='account-info-box-wrapper'>
                            <span>Status</span>
                            <span style={{ color: 'green' }}>Active</span>
                        </div>
                        <hr className='line'/>
                        <div className='account-info-box-wrapper'>
                            <span>Suspend</span>
                            <Switch/>
                        </div>
                        <hr className='line'/>
                        <div className='account-info-box-wrapper'>
                            <span>Delete</span>
                            <Button1 text='Remove' color='white' backgroundColor='red'/>
                        </div>
                        {/* <hr className='line'/>
                        <div className='account-info-box-wrapper'>
                        <div className="account-info-box-text">
                                <span>Mobile</span>
                                <p>verified</p>
                            </div>
                            <span>123456789</span>
                        </div> */}
                        {/* <hr className='line'/>
                        <div className='account-info-box-wrapper'>
                            <span>Date Joined</span>
                            <span>25-11-23</span>
                        </div> */}
                        </div>
                    <div className='account-info-box2'>
                        <div className='account-info-box-head account-info-box-wrapper'>Rent Info</div>
                        <hr className='line'/>
                        <div className='account-info-box-wrapper'>
                            <span>Name</span>
                            <span>Fun house</span>
                        </div>
                        <hr className='line'/>
                        <div className='account-info-box-wrapper'>
                                <span>Location</span>
                            <span>No 12 blah blah</span>
                        </div>
                        <hr className='line'/>
                        <div className='account-info-box-wrapper'>
                        <div className="account-info-box-text">
                                <span>Mobile</span>
                                <p>verified</p>
                            </div>
                            <span>123456789</span>
                        </div>
                        <hr className='line'/>
                        <div className='account-info-box-wrapper'>
                            <span>Date Joined</span>
                            <span>25-11-23</span>
                        </div>
                        <hr className='line'/>
                        <div className='account-info-box-wrapper'>
                            <span>Owner</span>
                            <Button1 text='View' backgroundColor='#0052FF' color='white'/>
                        </div>
                        </div>
                    <div className='account-info-box2'>
                        <div className='account-info-box-head account-info-box-wrapper'>Description</div>
                        <hr className='line'/>
                        <div className='account-info-description'>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi perferendis vero vitae, exercitationem sequi dolores quidem veniam vel odio nemo distinctio ipsam sed illo quaerat suscipit cupiditate quibusdam esse ipsa?
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quo, fuga? Vero, eaque totam. Ea deserunt labore, numquam maxime ipsum aliquid, illum nihil expedita aliquam, et culpa quis totam illo voluptatibus!
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}