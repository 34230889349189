import EChartsReact from "echarts-for-react"

export default function DashboardPie ({ tenants, landlords }){
    
const option = {
    tooltip : {
        trigger: 'item',
        formatter: "{a} <br/>{b} : {c} ({d}%)"
    },
    legend: {
        orient: 'vertical',
        left: 'bottom',
        data: ['Option 1', 'Option 2', 'Option 3']
    },
    series : [
        {
            name: 'Pie Chart',
            type: 'pie',
            radius : '55%',
            center: ['50%', '60%'],
            data:[
                {value:landlords, name:'landlords'},
                {value:tenants, name:'Tenants'}
            ],
            itemStyle: {
                emphasis: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
            }
        }
    ]
};

return(
    <EChartsReact
     option={option}
     style={{height: '300px', width: '100%'}}
    />
)
} 