import './styles.css'

export const Button1 = ({text, color, backgroundColor,action }) => {
    return (
        <button
         style={{ 
            backgroundColor, 
            color
         }} 
         onClick={() => action()}
         className='button-container'
        >
            {text}
        </button>
    )
}