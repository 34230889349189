import './styles.css'

export const Activities = () => {
    return (
        <div className="settings-activities-page">
            <div className="settings-activities-page-wrapper">
            <div className='account-info-box2'>
              <div className='account-info-box-head account-info-box-wrapper'>Recent Activites</div>
              <hr className='line'/>
              <div className='account-info-box-wrapper'>
                <span>Login Attempt</span>
                <span>20-11-23, 2:30 pm</span>
              </div>
              <hr className='line'/>
              <div className='account-info-box-wrapper'>
                    <span>Blocked a User </span>
                    <span>20-11-23, 2:30 pm</span>
              </div>
              <hr className='line'/>
              <div className='account-info-box-wrapper'>
                <span>Approved a premium Ad</span>
                <span>20-11-23, 2:30 pm</span>
              </div>
              <hr className='line'/>
              <div className='account-info-box-wrapper'>
                <span>Mailed John Doe</span>
                <span>20-11-23, 2:30 pm</span>
              </div>
              <hr className='line'/>
              <div className='account-info-box-wrapper'>
                <span>Login Attempt</span>
                <span>20-11-23, 2:30 pm</span>
              </div>
              <hr className='line'/>
              <div className='account-info-box-wrapper'>
                <span>Login Attempt</span>
                <span>20-11-23, 2:30 pm</span>
              </div>
              <hr className='line'/>
              <div className='account-info-box-wrapper'>
                    <span>Blocked a User </span>
                    <span>20-11-23, 2:30 pm</span>
              </div>
              <hr className='line'/>
              <div className='account-info-box-wrapper'>
                <span>Approved a premium Ad</span>
                <span>20-11-23, 2:30 pm</span>
              </div>
              <hr className='line'/>
              <div className='account-info-box-wrapper'>
                <span>Mailed John Doe</span>
                <span>20-11-23, 2:30 pm</span>
              </div>
              <hr className='line'/>
              <div className='account-info-box-wrapper'>
                <span>Login Attempt</span>
                <span>20-11-23, 2:30 pm</span>
              </div>
            </div>
            </div>
        </div>
    )
}