import './styles.css'
import Image from '../../../../assets/img4.jpg'
import Image2 from '../../../../assets/img2.jpg'
import Image3 from '../../../../assets/img3.webp'
import Carousel from 'react-bootstrap/Carousel';
import { useState } from 'react'
import { MdCancel } from "react-icons/md";

export const PremiumAddPop = ({ showDetails, propertyData }) => {

    const [index, setIndex] = useState(0);
    console.log({propertyData})

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };

    return (
        <div className="premium-ads-page-container-main page">
            <div className="premium-ads-page-wrapper-main">
                <div className="premium-ads-page-intro">
                    <h5>Property Details</h5>
                    <MdCancel
                     className='exit-icon' 
                     onClick={() => showDetails()}
                    />
                </div>
                <div className="carousel-container">
                    <div className="carousel-sub-container">
                    <Carousel activeIndex={index} onSelect={handleSelect}>
                    <Carousel.Item>
                        <img src={Image} text="First slide" />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img src={Image2} text="Second slide" />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img src={Image3} text="Third slide" />
                    </Carousel.Item>
                    </Carousel>
                    <div className="premium-ads-button-actions">
                        <button className='premium-ads-button-approve g-button'>Approve</button>
                        <button className='premium-ads-button-suspend g-button'>Suspend</button>
                        <button className='premium-ads-button-delete g-button'>Delete</button>
                    </div>
                    </div>
                <div className="premium-ads-page-data-container">
                    <div className="premium-ads-page-data">
                        <h5>Property Info</h5>
                        <hr />
                        <div className="premium-ads-main-data">
                            <span>Title</span>
                            <span>{propertyData.property_title}</span>
                        </div>
                        <div className="premium-ads-main-data">
                            <span>Category</span>
                            <span>{propertyData.property_category}</span>
                        </div>
                        <div className="premium-ads-main-data">
                            <span>Type</span>
                            <span>{propertyData.property_category}</span>
                        </div>
                        <div className="premium-ads-main-data">
                            <span>Distress Sale</span>
                            <span>{propertyData.distress_deal}</span>
                        </div>
                        <div className="premium-ads-main-data">
                            <span>Price</span>
                            <span>${propertyData.price}</span>
                        </div>
                        {/* <div className="premium-ads-main-data">
                            <span>Title</span>
                            <span>The fun house</span>
                        </div> */}
                    </div>
                    <div className="premium-ads-page-data-head">
                        <h5>Features</h5>
                        <hr />
                        <div className="premium-ads-main-data">
                            <span>Bedroom</span>
                            <span>5</span>
                        </div>
                        <div className="premium-ads-main-data">
                            <span>Parlor</span>
                            <span>2</span>
                        </div>
                        <div className="premium-ads-main-data">
                            <span>Kitchen</span>
                            <span>1</span>
                        </div>
                        <div className="premium-ads-main-data">
                            <span>Bathroom</span>
                            <span>6</span>
                        </div>
                    </div>
                    {propertyData.address && <div className="premium-ads-page-data-head">
                        <h5>Address</h5>
                        <hr />
                        <div className="premium-ads-main-data">
                            {propertyData.address}
                        </div>
                    </div>}
                    {propertyData.province && <div className="premium-ads-page-data-head">
                        <h5>Province</h5>
                        <hr />
                        <div className="premium-ads-main-data2">
                            {propertyData.province}
                        </div>
                    </div>}
                    {propertyData.area && <div className="premium-ads-page-data-head">
                        <h5>Area</h5>
                        <hr />
                        <div className="premium-ads-main-data2">
                            {propertyData.area}
                        </div>
                    </div>}
                    {propertyData.property_description && <div className="premium-ads-page-data-head">
                        <h5>Description</h5>
                        <hr />
                        <div className="premium-ads-main-data2">
                            {propertyData.property_description}
                        </div>
                    </div>}
                </div>
                </div>
            </div>
        </div>
    )
}