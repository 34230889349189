import axios from 'axios';
import { useState } from 'react';
import { serverOne } from '../../../helpers/server';

export const useGetGuides = () => {
    const [ loading, setLoading ] = useState(false)
    const [ error, setError ] = useState(false)
    const [ errorMessage, setErrorMessage ] = useState('')
    const [ result, setResult ] = useState([])
    const [ totalGuide, setTotalGuides ] = useState(0)
    const [ totalAuthors, setTotalAuthors ] = useState(0)
    const getGuides = async() => {
        try{
            setLoading(true)
            setError(false)
            setErrorMessage('')
            const response = await axios({
                method: 'GET',
                url: `${serverOne}/guides`
            })
            if(response.data.status !== 'Success'){
                setError(true)
                setLoading(false)
                console.log(response.data)
            }
            setResult(response.data.doc)
            setTotalGuides(response.data.result)
            console.log(response.data.doc)
            const docs = await response.data.doc
            const authors =await new Set(docs.map( guide => guide.userId))
            const test = Array.from(authors)
            console.log(test.length)
            setTotalAuthors(test.length)
            setLoading(false)
            setError(false)
            setErrorMessage('')
        } catch(error){
            setLoading(false)
            setError(true)
            console.log(error)
            // setErrorMessage(error.response.data.message)
        }
    }
    return { loading, error, errorMessage, result, getGuides, totalGuide, totalAuthors }
};