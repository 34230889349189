import * as React from 'react';
import Popover from '@mui/material/Popover';
import { SlOptionsVertical } from "react-icons/sl";
import { FaTrash } from "react-icons/fa";
import { LiaEdit } from "react-icons/lia";
import './styles.css'

export default function BasicPopover({ showDetails, data, setData, deleteDoc }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <SlOptionsVertical aria-describedby={id} variant="contained" onClick={handleClick} style={{ cursor:'pointer' }}/>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'middle',
          horizontal: 'right',
        }}
      >
        <div className="popover-container">
            <div
             className="pop-button-action pop-view-button"
             onClick={() => {
              showDetails()
              setData(data)
            }}
            >
                <LiaEdit/> <span>View</span>
            </div>
            <div className="pop-button-action pop-delete-button"
              onClick={() => deleteDoc('users',data.id, 'User deleted')}
            >
                <FaTrash/> <span>Delete</span>
            </div>
        </div>
      </Popover>
    </div>
  );
}