import './styles.css'
// react icons
import { FaUserCircle } from 'react-icons/fa'
import { useHistory } from '../../hooks/useHistory'
import Image from '../../assets/profile.jpg'

export const User = () => {
  const { goBack } = useHistory()
  return (
    <div className='account-page-container page'>
      <div className='account-page-wrapper'>
        <div className="back-button-container">
            <h3 onClick={() => goBack()}>Back</h3>
        </div>
          <div className='account-information-container'>
            <div className='account-info-box1'>
              <div className='account-image-container'>
              {/* <FaUserCircle className='account-image-icon'/> */}
              <div className='user-image'>
                  <img src={Image} alt='tester'/>
                </div>
              </div>
              <div className='account-user-info'>
                <span>WIsdom woke</span>
                <p>wisdom@gmail.com</p>
              </div>
            </div>

            {/* account box 2 */}
            <div className='account-info-box2'>
              <div className='account-info-box-head account-info-box-wrapper'>Actions</div>
              <hr className='line'/>
              <div className='account-info-box-wrapper'>
                <span>Block User</span>
                <div className="user-account-button-container">
                    <button>Block</button>
                </div>
              </div>
              <div className='account-info-box-wrapper'>
                <span>Mail</span>
                <div className="user-account-button-container">
                    <button>Mail</button>
                </div>
              </div>
              <div className='account-info-box-wrapper'>
                <span>Delete Account</span>
                <div className="user-account-button-container">
                    <button>Delete</button>
                </div>
              </div>
              {/* <hr className='line'/>
              <div className='account-info-box-wrapper'>
                <span>Phone number</span>
                <span>123456789</span>
              </div> */}
            </div>

            {/* account box 2 */}
            <div className='account-info-box2'>
              <div className='account-info-box-head account-info-box-wrapper'>Contact Info</div>
              <hr className='line'/>
              <div className='account-info-box-wrapper'>
                <span>Username</span>
                <span>wisdom woke</span>
              </div>
              <div className='account-info-box-wrapper'>
                <div className="account-info-box-text">
                    <span>Email</span>
                    <p>verified</p>
                </div>
                <span>wisdom@gmail.com</span>
              </div>             <div className='account-info-box-wrapper'>
              <div className="account-info-box-text">
                    <span>Mobile</span>
                    <p>verified</p>
                </div>
                <span>123456789</span>
              </div>
              <div className='account-info-box-wrapper'>
                <span>Date Joined</span>
                <span>25-11-23</span>
              </div>
            </div>

            {/* <div className="admin-user-deposit-log">
            <h3>Properties</h3>
            <div className="admin-user-deposit-table">
                <table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Location</th>
                            <th>Date</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                          <td>EL's</td>
                          <td>19 house no</td>
                          <td>20-11-23</td>
                          <td>
                            <button>View</button>
                          </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div> */}

            {/* <div className='account-info-box2'>
              <div className='account-info-box-head account-info-box-wrapper'>Personal Info</div>
              <hr className='line'/>
              <div className='account-info-box-wrapper'>
                <span>School</span>
                <span>{user.school}</span>
              </div>
              <hr className='line'/>
              <div className='account-info-box-wrapper'>
                <span>Course</span>
                <span>{user.study}</span>
              </div>
              <hr className='line'/>
              <div className='account-info-box-wrapper'>
                <span>Education Level</span>
                <span>{user.levelOfEducation}</span>
              </div>
            </div>
            <div className='account-info-box2 account-delete-container'>
              <div className='account-info-box-head account-info-box-wrapper'>Delete Account</div>
              <div className='account-info-box-wrapper'>
                <p>Note that by taking this action you will DEACTIVATE your account an this action can be only be taken back by following all due process</p>
              </div>
              <div className='account-delete-button'>
                <button>Delete Account</button>
              </div>
            </div> */}

          </div>
      </div>
    </div>
  )
}
