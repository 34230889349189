import TableComponents from "./components/table"
import './styles.css'
import { FaUser } from "react-icons/fa"
import { HiOutlineHomeModern } from "react-icons/hi2";
import { UseGetProperties } from "./hooks/useGetProperties";
import { PreLoader } from "../../utils/preloader";
import { UseGetUsers } from "../users/hooks/getUsers";
import { useEffect } from "react";

export const PremiumAds = () => {
    const { getProperties, result, loading } = UseGetProperties()
    const { isLoading, getUsers, totalLandlords } = UseGetUsers()

    useEffect(() => {
        getUsers()
    }, [])

    return (
        <div className="premiumAds-page-container page">
            <div className="premiumAds-page-wrapper">
                <div className="premiumAds-page-first-container">
                    <div className="premiumAds-page-text">
                        <h3>Order List</h3>
                        <div className="order-list-con">
                            <h4>Properties</h4>
                        </div>
                    </div>
                    <div className="premiumAds-page-box-container">
                        <div className="premium-page-box">
                            <div className="premium-page-box-text">
                                <h2>{totalLandlords}</h2>
                                <p>Total Landlords</p>
                            </div>
                            <div className="premium-page-box-icon-con">
                                <FaUser className="premium-page-box-icon"/>
                            </div>
                        </div>
                        <div className="premium-page-box">
                            <div className="premium-page-box-text">
                                <h2>{result.length !== 0 ? result.length : 0}</h2>
                                <p>Available Properties</p>
                            </div>
                            <div className="premium-page-box-icon-con">
                                <HiOutlineHomeModern className="premium-page-box-icon"/>
                            </div>
                        </div>
                    </div>
                </div>
                <TableComponents getData={getProperties} result={result} />
            </div>
            {(loading || isLoading) && <PreLoader/>}
        </div>
    )
}