import axios from 'axios'
import { serverOne } from '../../../helpers/server'
import { useState } from 'react'
import { useAuthContext } from '../../../hooks/useAuthContext'

export const useCreateGuide = () => {

    const [ loading, setLoading ] = useState(false)
    const [ error, setError ] = useState(false)
    const [ success, setSuccess ] = useState(false)
    const [ errorMessage, setErrorMessage ] = useState('')
    const { user } = useAuthContext()

    const createGuide = async(
        title,
        post,
        coverImage,
        category
        ) => {
        try{
            console.log({coverImage})
            setLoading(true)
            setError(false)
            setSuccess(false)
            const formData = new FormData()
            formData.append('title', title)
            formData.append('content', post)
            formData.append('coverImage', coverImage)
            formData.append('type', category)
            formData.append('userId', user.id)
            formData.append('user_name', `${user.firstName} ${user.lastName}`)
            const response = await axios.post(`${serverOne}/guides`, formData)
            if(response.data.status !== 'Success'){
                setError(true)
                setLoading(true)
                setSuccess(false)
            }
            setLoading(false)
            setSuccess(true)
            setError(false)
        } catch(error){
            setError(true)
            setLoading(false)
            setSuccess(false)
            console.log(error.response)
            setErrorMessage(error.response.data.message)
        }
    }
    return { loading, error, success, createGuide, errorMessage }
}