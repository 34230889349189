import axios from 'axios';
import { useState } from 'react';
import { serverOne } from '../../../helpers/server';

export const useUpdateGuide = () => {
    const [ updateError, setError ] = useState(false);
    const [ updateLoading, setUpdateLoading ] = useState(false);
    const [ updateErrorMessage, setErrorMessage ] = useState('');
    const [ updateSuccess, setUpdateSuccess ] = useState(false);

    const updateGuide = async(id, data) => {
        try{
            setUpdateLoading(false)
            setError(false)
            setErrorMessage('')
            setUpdateSuccess(false)
            const response = await axios({
                method: 'PATCH',
                url: `${serverOne}/guides/${id}`,
                data
            })
            if(response.data.status !== 'Success'){
                setUpdateLoading(false)
                setError(true)
                setErrorMessage('Could not complete action')
                setUpdateSuccess(false)
            };
            setError(false)
            setUpdateLoading(false)
            setUpdateSuccess(true)
            setErrorMessage('')
        } catch(error){
            setErrorMessage(error.response.data.message)
            setError(true)
            setUpdateLoading(false)
            setUpdateSuccess(false)
        }
    }
    return { updateLoading, updateError, updateErrorMessage, updateSuccess, updateGuide }
}