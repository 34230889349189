import './styles.css'
import RentsTable from "./components"
import { PremiumAds } from '../premiumAds'


const rents = []

export const Properties = () => {
    const hello = () => {
        console.log('Hello')
    }
    return (
        <>
            <PremiumAds/>
            {/* <div className="rent-page-container page">
                <div className="rent-page-wrapper">
                    <RentsTable result={rents} getData={hello}/>
                </div>
            </div> */}
        </>
    )
}