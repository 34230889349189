import EChartsReact from "echarts-for-react"
export default function DashboardBarChart() {
    const options = {
        xAxis: {
            data: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20'],
            axisLabel: {
              inside: false,
              color: '#fff'
            },
            axisTick: {
              show: false
            },
            axisLine: {
              show: false
            },
            z: 10
          },
          yAxis: {
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              color: '#999'
            }
          },
          /* dataZoom: [
            {
              type: 'inside'
            }
          ], */
          series: [
            {
              type: 'bar',
              showBackground: true,
              data: [220, 182, 191, 234, 290, 330, 310, 123, 442, 321, 90, 149, 210, 122, 133, 334, 198, 123, 125, 220]
            }
          ]
      };
    return(
        <EChartsReact style={{ width:'100%', margin:0, padding:0 }} option={options}/>
    )
}