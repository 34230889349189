import { useState } from 'react'
import axios from 'axios'
import { serverOne } from '../helpers/server'
import cookies from 'js-cookies'
import { useAuthContext } from './useAuthContext'
export const UseReLogin = () => {
    const jwt = cookies.getItem('jwt')
    const [ error, setError ] = useState('')
    const { dispatch } = useAuthContext()
    const relogin = async () => {
        try{
            console.log({jwt})
            if(jwt !== null){
                //console.log('Hello')
                const response = await axios({
                    method: 'POST',
                    url: `${serverOne}/auth/relogin`,
                    headers:{
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + jwt
                    },
                    data:{
                        jwt
                    }
                })
                if(response.data.status !== 'Success'){
                    //console.log(response.data)
                } else{
                    //console.log(response.data)
                    dispatch({ type: 'LOGIN', payload: response.data.doc[0] })
                }
            }
        } catch(error){
            console.log(error)
            setError('Something went wrong')
            //console.log(error)
        }
    }
    return { relogin, error }
} 