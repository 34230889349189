import axios from "axios"
import { useState } from "react"
import { serverOne } from "../helpers/server";

export const useDeleteDoc = () => {
    const [ dLoading, seDtLoading ] = useState(false)
    const [ dError, setDError ] = useState(false)
    const [ dErrorMessage, setDErrorMessage ] = useState(false)
    const [ dSuccess, setDSuccess ] = useState(false)
    const [ dSuccessMessage, setDSuccessMessage ] = useState(false)
    
    const deleteDoc = async(endpoint, id, message) => {
        try{
            setDError(true)
            setDSuccess(false)
            setDErrorMessage('')
            setDSuccessMessage('')
            seDtLoading(false)
            const response = await axios({
                method: 'DELETE',
                url: `${serverOne}/${endpoint}/${id}`
            })
            if(response.data.status !== 'Success'){
                setDError(true)
                setDSuccess(false)
                setDErrorMessage('Some thing went wrong')
                setDSuccessMessage('')
                seDtLoading(false)
            }
            setDError(false)
                setDSuccess(true)
                setDErrorMessage('')
                setDSuccessMessage(message)
                seDtLoading(false)
        } catch(error){
            setDError(true)
            setDSuccess(false)
            setDErrorMessage(error.response.data.message)
            setDSuccessMessage('')
            seDtLoading(false)
        }
    };
    return { deleteDoc, dLoading, dError, dErrorMessage, dSuccess, dSuccessMessage }
}

