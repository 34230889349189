import { GiCheckMark } from 'react-icons/gi'
import './styles.css'
import { motion } from 'framer-motion'
import { useState } from 'react'

export const Success = ({ text }) => {

    const [ show, setShow ] = useState(true)

    const handleHideContainer = () => {
        setShow(false)
    }

    return(
        <>
            { show &&  <div className="marker-container page">
                <motion.div className="marker-wrapper"
                    initial={{ scale: 0 }}
                    animate={{ scale: 1 }}
                    transition={{
                        type: 'spring',
                        stiffness: 260,
                        damping: 20
                    }}
                >
                    <div className='marker-icon-container'>
                        <div className="marker-icon-min-container">
                            <div className='icon-container'>
                                <GiCheckMark className='marker-icon-good'/>
                            </div>
                            <h4>Success</h4>
                            {text && <h6>{text}</h6>}
                        </div>
                        <div className="marker-button-container">
                            <button 
                             onClick={() => handleHideContainer()}
                            >OK</button>
                        </div>
                    </div>
                </motion.div>
            </div>}
        </>
    )
}