import axios from 'axios';
import { useState } from 'react';
import { serverOne } from '../../../helpers/server';

export const useUpdateUser = () => {
    const [ isLoading, setIsLoading ] = useState(false)
    const [ error, setError ] = useState(false)
    const [ success, setSuccess ] = useState(false)
    const [ successMessage, setSuccessMessage ] = useState('')
    const [ errorMessage, setErrorMessage ] = useState('')

    const updateUser = async (id, data,message) => {
        try{
            setError(false)
            setIsLoading(false)
            setSuccess(false)
            setErrorMessage('')
            setSuccessMessage('')
            const response = await axios({
                method: "PATCH",
                url: `${serverOne}/users/${id}`,
                data
            })
            console.log(response.data)
            if(response.data.status !== 'Success'){
                setError(true)
                setIsLoading(false)
                setSuccess(false)
                setErrorMessage('SomeThing went wrong')
                setSuccessMessage('')
            }
            setError(false)
            setIsLoading(false)
            setSuccess(true)
            setErrorMessage('')
            setSuccessMessage(message)
        } catch(error){
            setError(true)
            setIsLoading(false)
            setSuccess(false)
            setErrorMessage(error.response.data.message)
        }
    };
    return { isLoading, error, success, errorMessage, updateUser, successMessage }
};