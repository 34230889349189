import './styles.css'
import Image from '../../../../assets/profile.jpg'
import { useUpdateUser } from '../../hooks/useUpdateUser';
import { useDeleteDoc } from '../../../../hooks/useDeleteDoc';
import { Success } from '../../../../utils/marker/success';
import { ErrorMarker } from '../../../../utils/marker/error';
import { PreLoader } from '../../../../utils/preloader';
import { MdCancel } from "react-icons/md";

export const UserPop = ({ showDetails, userDetails }) => {
    const { isLoading, updateUser, error, errorMessage, success, successMessage } = useUpdateUser()
    const { deleteDoc, dError, dloading, dSuccess, dSuccessMessage, dErrorMessage } = useDeleteDoc()
    const date = new Date(userDetails.created_at);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const formattedDate = `${day}-${month}-${year}`;
    return (
        <div className="user-pop-container page">
            <div className="user-pop-wrapper">

                <div className="user-pop-text">
                    <h5>User Details</h5>
                    <MdCancel
                     className='exit-icon' 
                     onClick={() => showDetails()}
                    />
                </div>

                <div className="user-pop-main-wrapper">
                    <div className="user-pop-sub-container user-pop-container2">
                        <div className="user-pop-sub-image-container">
                            <img src={Image} alt='test'/>
                        </div>
                        <div className="user-pop-sub-verify-button-container">
                            {!userDetails.is_verified && <button className='pop-verify-btn'
                            onClick={() => updateUser(
                                    userDetails.id,
                                    { is_verified: true},
                                    'User verified'
                                )}
                            >Verify</button>}
                            <button className='pop-block-btn'>Block</button>
                            <button className='pop-delete-btn'
                                onClick={() => deleteDoc('users', userDetails.id, 'User deleted')}
                            >Delete</button>
                            <button className='pop-verify-btn'>View ID</button>
                        </div>
                    </div>
                    <div className="user-pop-sub-container user-pop-details">
                        <div className="user-pop-detail-mini">
                            <h5>First Name:</h5>
                            <div className='dt'>{userDetails.firstName}</div>
                        </div>
                        <div className="user-pop-detail-mini">
                            <h5>Last Name:</h5>
                            <div className='dt'>{userDetails.lastName}</div>
                        </div>
                        <div className="user-pop-detail-mini">
                            <h5>Mobile:</h5>
                            <div className='dt'>{userDetails.phone}</div>
                        </div>
                        <div className="user-pop-detail-mini">
                            <h5>Email:</h5>
                            <div className='dt'>{userDetails.email}</div>
                        </div>
                        <div className="user-pop-detail-mini">
                            <h5>Country:</h5>
                            <div className='dt'>{userDetails.country}</div>
                        </div>
                        <div className="user-pop-detail-mini">
                            <h5>Joined Date:</h5>
                            <div className='dt'>{formattedDate}</div>
                        </div>
                        <div className="user-pop-detail-mini">
                            <h5>Reviews</h5>
                            <div>
                                <button>View</button>
                            </div>
                        </div>
                        <div className="user-pop-detail-mini">
                            <h5>Last login:</h5>
                            <div className='dt'>10:30am, 29-11-2023</div>
                        </div>
                    </div>
                </div>
            </div>
            { (isLoading || dloading) && <PreLoader/> }
            { error && <ErrorMarker text={errorMessage}/> }
            { success && <Success text={successMessage}/> }
            { dSuccess && <Success text={dSuccessMessage}/> }
            { dError && <ErrorMarker text={dErrorMessage} /> }
        </div>
    )
}