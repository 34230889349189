import './styles.css'
export const PreLoader = () => {
    return (
        <div className="preloader-container">
            <div className="preloader-wrapper">
                <div class="spinner">
                    <div></div>   
                    <div></div>    
                    <div></div>    
                    <div></div>    
                </div>
            </div>
        </div>
    )
}