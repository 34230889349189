import axios from 'axios'
import { useState } from 'react'
import { serverOne } from '../../../helpers/server'

export const UseGetProperties = () => {
    const [ loading, setLoading ] = useState(false)
    const [ result, setResult ] = useState([])
    const [ error, setError ] = useState(false)

    const getProperties = async() => {
        try{
            setError(false)
            setLoading(true)
            const response = await axios({
                method: 'GET',
                url: `${serverOne}/properties`
            })
            if(response.data.status !== 'Success'){
                setError(true)
                setLoading(false)
            }
            setResult(response.data.doc)
            setLoading(false)
        } catch(error){
            setLoading(false)
            setError(true)
        }
    }
    return { getProperties, error, loading, result }
};