import { useState } from 'react';
import axios from 'axios';
import { serverOne } from '../../../helpers/server';

export const useDeleteGuide = () => {
    const [ error, setError ] = useState(false);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ errorMessage, setErrorMessage ] = useState('');
    const [ success, setSuccess ] = useState(false);
    const deleteGuide = async(id) => {
        try{
            setIsLoading(false)
            setError(false)
            setErrorMessage('')
            setSuccess(false)
            const response = await axios({
                method: 'DELETE',
                url: `${serverOne}/guides/${id}`
            })
            console.log(response)
            if(response.data.status !== 'Success'){
                setIsLoading(false)
                setError(true)
                setErrorMessage('Could not complete action')
                setSuccess(false)
                console.log(response.doc)
            }
            setError(false)
            setIsLoading(false)
            setSuccess(true)
            setErrorMessage('')
        } catch(error){
            console.log(error)
            setErrorMessage(error.response.data.message)
            setError(true)
            setIsLoading(false)
            setSuccess(false)
        }
    };
    return { isLoading, error, errorMessage, success, deleteGuide }
}