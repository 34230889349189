import EChartsReact from "echarts-for-react";
export default function DoughnutChart({ rents, sale }) {
    const option = {
       /*  title : {
            text: 'Pie Chart Example',
            x:'center'
        }, */
        tooltip : {
            trigger: 'item',
            formatter: "{b} : {c} ({d}%)"
        },
        legend: {
            orient: 'vertical',
            left: 'left',
            data: ['Option 1', 'Option 2', 'Option 3']
        },
        series : [
            {
                name: 'Access From',
                type: 'pie',
                radius: ['80%', '100%'],
                avoidLabelOverlap: false,
                label: {
                  show: false,
                  position: 'center'
                },
                emphasis: {
                  label: {
                    show: true,
                    fontSize: 10,
                    fontWeight: 'bold'
                  }
                },
                labelLine: {
                  show: false
                },
                data: [
                    { value: rents, name: 'Rented' },
                    { value: sale, name: 'for sale' },
                  ]
            }
        ]
    };
    
    return(
        <EChartsReact
         option={option}
         style={{height:'100px' ,width: '100%'}}
        />
    )
}
