import './styles.css'
import DoughnutChart from '../charts/doughnut'

export const DashboardMiniAnalysis = ({ number, text, text2, rents, sale }) => {
    return (
        <div className="dashboard-mini-analysis-container">
            <div className="dashboard-mini-analysis-text">
                <h2>{number}</h2>
                <h5>{text}</h5>
                <p>{text2}</p>
            </div>
            <div className="dashboard-mini-analysis-chart">
                <DoughnutChart rents={rents} sale={sale}/>
            </div>
        </div>
    )
}