import { Button1 } from "../../../../components/button/button1"
import Switch from '@mui/material/Switch'
import './styles.css'

export const Security = () => {
    return (
        <div className="security-page-container">
            <div className="security-page-wrapper">
                <div className="password-change-container">
                    <h3>Change Password</h3>
                    <hr/>
                    <label>
                        <span>Current Password</span>
                        <input type="text" className="security-input" placeholder="Enter Current Password" />
                    </label>
                    <label>
                        <span>New Password</span>
                        <input type="text" className="security-input" placeholder="Enter New Password" />
                    </label>
                    <label>
                        <span>Confirm Password</span>
                        <input type="text" className="security-input" placeholder="Re-Enter New Password"/>
                    </label>
                    <div className="show-password-container">
                        <input type="checkbox"/>
                        <span>Show password</span>
                    </div>
                    <div className="security-password-container">
                        <Button1 color='white' backgroundColor='black' text='Save'/>
                    </div>
                </div>
            </div>
            <div className="security-page-wrapper security-wrapper2">
            <div className="password-change-container">
                    <h3>Two Factor Authentication</h3>
                    <hr/>
                    <div className="2fa-container">
                        <span>Enable 2 factor authentication</span>
                        <Switch/>
                    </div>
                    <div className="security-password-container">
                        <Button1 color='white' backgroundColor='black' text='Save'/>
                    </div>
                </div>
            </div>
        </div>
    )
}