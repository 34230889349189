import { BrowserRouter, Route, Routes } from 'react-router-dom';
import 'bootstrap';
import 'bootstrap/js/dist/util';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './App.css';

// Components
import NavbarComponent from './components/nav';
// Context
import { useAuthContext } from './hooks/useAuthContext';
import { UseReLogin } from './hooks/useRelogin';

// Pages
import { Login } from './pages/auth';
import { NotFound } from './pages/not-found';
import { Dashboard } from './pages/dashboard';
import { UserList } from './pages/users';
import { User } from './pages/user';
import { Properties } from './pages/properties';
import { PremiumAds } from './pages/premiumAds';
import { PremiumAd } from './pages/premiumAds/page/premiumAd';
import { Rents } from './pages/rents';
import { Rent } from './pages/rents/pages';
import { Settings } from './pages/settings'; 
import { Guides } from './pages/guides'
import { UserNotFound } from './pages/not-found/user';
import { Tickets } from './pages/tickets';
import { Report } from './pages/report';
import { AdminUsers } from './pages/adminUsers';
import { Disputes } from './pages/disputes';
import { PremiumProperties } from './pages/properties/pages/premium';
import { GuidesDetails } from './pages/guides/pages/detail';
import { useEffect } from 'react';

function App() {
  const { user } = useAuthContext()
  const { relogin } = UseReLogin()

  useEffect(() => {
    relogin()
  },[])

  return (
    <div className="App">
      <BrowserRouter> 
      {user && <NavbarComponent/>}
        <Routes>
          <Route path='/auth' element={user ? <Dashboard/> :<Login/>}/>
          <Route path='*' element={user ? <UserNotFound/>:<NotFound/>}/>
          <Route path='/' element={ user ? <Dashboard/> : <Login/>}/>
          <Route path='/dashboard' element={ user ? <Dashboard/> : <Login/>}/>
          <Route path='/users' element={ user ? <UserList/> : <Login/>}/>
          <Route path='/user/:id' element={ user ? <User/> : <Login/>}/>
          <Route path='/properties' element={ user ? <Properties/> : <Login/>}/>
          <Route path='/premium_ads' element={user ? <PremiumAds/> : <Login/>}/>
          <Route path='/premium_ads/:id' element={user ? <PremiumAd/> : <Login/>}/>
          <Route path='/rent/:id' element={user ? <Rent/> : <Login/>}/>
          <Route path='/rents' element={user ? <Rents/> : <Login/>}/>
          <Route path='/settings' element={user ? <Settings/> : <Login/>} />
          <Route path='/guides' element={user ? <Guides/> : <Login/>}/>
          <Route path='/guides/:id' element={user ? <GuidesDetails/> : <Login/>}/>
          <Route path='/reports' element={ user? <Report/> : <Login/>}/>
          <Route path='/tickets' element={ user ? <Tickets/> : <Login/>}/>
          <Route path='/admin_users' element={user ? <AdminUsers/> : <Login/>}/>
          <Route path='/disputes' element={user ? <Disputes/> : <Login/>}/>
          <Route path='/premium_properties' element={user ? <PremiumProperties/> : <Login/>} />
          </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
