import axios from 'axios'
import { useState } from 'react';
import { serverOne } from '../../../helpers/server';

export const useCreateAdmin = () => {
    const [ loading, setLoading ] = useState(false)
    const [ error, setError ] = useState(false)
    const [ success, setSuccess ] = useState(false)
    const [ errorMessage, setErrorMessage ] = useState('')

    const createAdmin = async(
        firstName, 
        lastName,
        middleName,
        email,
        password,
        phone
    ) => {
        try{
            setLoading(true)
            setError(false)
            setErrorMessage('')
            setSuccess(false)
            const response = await axios({
                method: 'POST',
                url:`${serverOne}/auth/signup`,
                data: {
                    firstName, 
                    lastName,
                    middelName: middleName,
                    email,
                    password,
                    phone
                }
            });
            if(response.data.status !== 'Success'){
                setLoading(false)
                setError(true)
                setSuccess(false)
                setErrorMessage('Some thing went wrong')
            }
            setLoading(false)
            setError(false)
            setSuccess(true)
            setErrorMessage('')

        } catch(error){
            setLoading(false)
            setError(true)
            setSuccess(false)
            setErrorMessage(error.response.data.message)
            console.log(error)
        }
    }
    return { loading, error, errorMessage, createAdmin, success }
};