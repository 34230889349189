import './styles.css'
import { GrSettingsOption } from "react-icons/gr";
import { HiOutlineHomeModern } from "react-icons/hi2";
import DashboardSlider from './components/slider';
import { DashboardMiniAnalysis } from './components/mini-analysis';
import DashboardBarChart from './components/charts/bar';
import DashboardDoubleLine from './components/charts/doubleLine';
import DashboardPie from './components/charts/pie';
import { Analysis } from './hooks/analysis';
import { PreLoader } from '../../utils/preloader';
import { useEffect } from 'react';
import DashboardReview from './components/reviews'

export const Dashboard = () => {
    const { loading, error, getAnalysis, totalProperties, totalUsers, totalTenants, totalLords, totalRents } = Analysis()

    useEffect(() => {
        getAnalysis()
    }, [])

    return (
        <div className="dashboard-container page">
            {!loading && <div className="dashboard-wrapper">
                <div className="dashboard-intro-container">
                    <div className="dashboard-intro-text">
                        <h2>Dashboard</h2>
                        <p>Welcome to imbapano Property Admin</p>
                    </div>
                    <div className="dashboard-intro-button-container">
                        <button className='dashboard-refresh-button'>Refresh</button>
                        <button className='dashboard-setting-button'>
                            <GrSettingsOption/>
                        </button>
                    </div>
                </div>

                <div className="dashboard-page-section1-container">
                    <div className="dashboard-page-section1-wrapper">
                        <div className="dashboard-page-section1-mini">    
                            <div className="dashboard-total-property-container">
                                <div className="total-property-icon-container">
                                    <HiOutlineHomeModern className='total-property-icon'/>
                                </div>
                                <div className="dashboard-total-property-text">
                                    <h2>Total-properties</h2>
                                    <div className="dashboard-range-container">
                                        <input type="range"/>
                                    </div>
                                    <p>{totalProperties} properties recorded so far</p>
                                </div>
                                <div className="dashboard-total-property-number">
                                    <h1>{totalProperties}</h1>
                                </div>
                            </div>
                            <div className="dashboard-mini-analysis">
                                <DashboardMiniAnalysis
                                number={+totalProperties - +totalRents}
                                text='Properties for Sale'
                                text2='Target 3k/month'
                                rents={totalRents}
                                sale={+totalProperties - +totalRents}
                                />
                                <DashboardMiniAnalysis
                                number={totalRents}
                                text='Properties for Rent'
                                text2='Target 3k/month'
                                rents={totalRents}
                                sale={+totalProperties - +totalRents}
                                />
                            </div>
                        </div>
                        <div className="dashboard-page-section1-mini">
                            <div className="dashboard-total-revenue-container">
                                <div className="dashboard-total-revenue-text1">
                                    <h3>Total Revenue</h3>
                                </div>
                                <div className="dashboard-revenue-text2">
                                    <h2>$0.00</h2>
                                    <p>last month $0.00</p>
                                </div>
                                <div className="dashboard-total-revenue-chart">
                                    <DashboardBarChart/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Dashboard Section 2 */}
                <div className="dashboard-page-section2-container">
                    <div className="dashboard-page-section2-wrapper">
                        <div className="dashboard-section2-mini">
                            <div className="dashboard-page-overview-container">
                                <div className="dashboard-page-overview-ft">
                                    <div className="overview-ft-intro-text">
                                        <h4>Overview</h4>
                                    </div>
                                    <div className='dashboard-overview-mini-main-box'>
                                        <div className="overview-ft-box">
                                            <div className="overview-ft-box-icon">
                                                <HiOutlineHomeModern className='ft-icon-main'/>
                                            </div>
                                            <div className="overview-ft-box-text">
                                                <p>Total Rent</p>
                                                <h4>{totalRents} Unit</h4>
                                            </div>
                                        </div>
                                        <div className="overview-ft-box">
                                            <div className="overview-ft-box-icon2">
                                                <HiOutlineHomeModern className='ft-icon-main'/>
                                            </div>
                                            <div className="overview-ft-box-text">
                                                <p>Total Sale</p>
                                                <h4>{+totalProperties -totalRents} Unit</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <DashboardDoubleLine/>
                                </div>
                            </div>
                        </div>
                        
                        <div className="dashboard-section2-main-mini">
                            <div className="dashboard-section2-mini">
                                <DashboardPie tenants={totalTenants} landlords={totalLords}/>
                            </div>
                            <div className="dashboard-section2-mini">
                                <div className="dashboard-slider-container">
                                    <label>
                                        <div className="slider-text-con">
                                            <span>Product view</span>
                                            <p>561/days</p>
                                        </div>
                                        <input type="range" />
                                    </label>
                                    <label>
                                        <div className="slider-text-con">
                                            <span>Product Listed</span>
                                            <p>561/days</p>
                                        </div>
                                        <input type="range" />
                                    </label>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                        {/* <DashboardReview/> */}
                </div>


            </div>}
            { loading && <PreLoader/> }
        </div>
    )
}