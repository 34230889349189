import './styles.css'
// Components
import { SideBar } from './components/sidebar'
import { Activities } from './pages/activities'
import { Security } from './pages/security'
import { useState } from 'react'

export const Settings = () => {
    const [ showActivities, setShowActivities ] = useState(true)
    const [ showSecurity, setShowSecurity ] = useState(false)

    const handleActivities = () => {
        setShowActivities(true)
        setShowSecurity(false)
    }

    const handleSecurity = () => {
        setShowActivities(false)
        setShowSecurity(true)
    }

    return (
        <div className="setting-page-container page">
            <div className="settings-page-wrapper">
                <SideBar activities={handleActivities} security={handleSecurity}/>
                <div className="settings-sub-page-container sub-page">
                    {showActivities && <Activities/>}
                    {showSecurity && <Security/>}
                </div>
            </div>
        </div>
    )
}