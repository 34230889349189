import './styles.css'
import TableComponents from './components/table';
import { CreateAdmin } from './components/new_user';
import { useCreateAdmin } from './hooks/useCreateAdmin';
import { useDeleteDoc } from '../../hooks/useDeleteDoc';
import { useGetData } from '../../hooks/useGetData';
import { PreLoader } from '../../utils/preloader';
import { Success } from '../../utils/marker/success';
import { ErrorMarker } from '../../utils/marker/error';

import { useEffect, useState } from 'react';

export const AdminUsers = () => {
    const { isLoading, isError, result, getData } = useGetData()
    const [ showCreateAdmin, setShowCreateAdmin ] = useState(false)
    const { loading, error, errorMessage, createAdmin, success } = useCreateAdmin()
    const { dLoading, dError, dErrorMessage, deleteDoc, dSuccess, dSuccessMessage } = useDeleteDoc()

    useEffect(() => {
        getData('admins')
    }, []);

    const handleShowCreateAdmin = () => {
        showCreateAdmin ? setShowCreateAdmin(false) : setShowCreateAdmin(true)
    };

    return (
        <div className="user-list-container page">
        {!isLoading && <div className="user-list-wrapper">
        <div className="users-page-box-container">
                <div className="add-button-container">
                    <button
                        className='admin-users-new-button'
                        onClick={() => handleShowCreateAdmin()}
                    >NEW </button>
                </div>
                <div className="admin_users-list-search-input-container">
                    <input type="text" placeholder='Search for users'/>
                    <button>Search</button>
                </div>
                </div>
            <TableComponents result={result} deleteDoc={deleteDoc}/>
            {showCreateAdmin && <CreateAdmin remove={handleShowCreateAdmin} createAdmin={createAdmin}/>}
        </div>}
        {(isLoading || loading || dLoading) &&  <PreLoader/>}
        { success && <Success text='account created'/> }
        { error && <ErrorMarker text={errorMessage}/> }
        {isError && <ErrorMarker/>}
        { dSuccess && <Success text={dSuccessMessage}/> }
        { dError && <ErrorMarker text={dErrorMessage}/> }
    </div>
    )
}