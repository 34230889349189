import './styles.css'
// Components
import { Button1 } from '../../../../components/button/button1'
// images
import Image1 from '../../../../assets/img1.jpg'
import Image2 from '../../../../assets/img2.jpg'
import Image3 from '../../../../assets/img3.webp'
import Image4 from '../../../../assets/img4.jpg'
import Image from '../../../../assets/img1.jpg'

export const PremiumAd = () => {
    const handleClick = () => {
        console.log('Hello There')
    }
    return (
        <div className="premiumAd-page-container page">
            <div className="premiumAds-page-wrapper">
                <div className="premium-page-intro-text">
                    <h3>Premium Ads</h3>
                </div>
                <div className="premium-page-button-container">
                    <Button1 text='Accept' color='white' backgroundColor='black' action={handleClick}/>
                    <Button1 text='Reject' color='white' backgroundColor='black' action={handleClick}/>
                </div>
                <div className="premium-page-image-containers">
                    <div className="premium-page-main-image-container">
                        <img src={Image1} alt="" />
                    </div>
                    {/* <div className="premium-page-sub-images">
                        <div className="premium-page-sub-image-container">
                            <img src={Image2} alt="test" />
                        </div>
                        <div className="premium-page-sub-image-container">
                            <img src={Image2} alt="test" />
                        </div>
                        <div className="premium-page-sub-image-container">
                            <img src={Image2} alt="test" />
                        </div>
                    </div> */}
                    <div className='account-info-box2'>
                        <div className='account-info-box-head account-info-box-wrapper'>Info</div>
                        <hr className='line'/>
                        <div className='account-info-box-wrapper'>
                            <span>Date Joined</span>
                            <span>23-11-23</span>
                        </div>
                        <div className='account-info-box-wrapper'>
                            <span>Customer name</span>
                            <span>wisdom woke</span>
                        </div>
                        <div className='account-info-box-wrapper'>
                            <div className="account-info-box-text">
                                <span>Customer Email</span>
                                <p>verified</p>
                            </div>
                            <span>wisdom@gmail.com</span>
                        </div>
                        <div className='account-info-box-wrapper'>
                        <div className="account-info-box-text">
                                <span>Customer Mobile</span>
                                <p>verified</p>
                            </div>
                            <span>123456789</span>
                        </div>
                        <div className='account-info-box-wrapper'>
                            <span>Customer Profile</span>
                            <Button1 text='View' color='white' backgroundColor='#0052FF'/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}