import './styles.css'
import { NavLink } from 'react-router-dom'
// react icons
import { IoNotificationsSharp } from "react-icons/io5";
import { MdSpaceDashboard } from "react-icons/md";
import { FaUserCircle } from 'react-icons/fa'
import  { FiLogOut } from 'react-icons/fi'
import { FaUsers } from "react-icons/fa";
import { FaUsersGear } from "react-icons/fa6";
import { useLogout } from '../../hooks/useLogout';
import { HiMiniHomeModern } from "react-icons/hi2";
import { SiHomebridge } from "react-icons/si";
import { MdOutlineBook } from "react-icons/md";
import { AiFillBook } from "react-icons/ai";
// framer motion
import { motion } from 'framer-motion'

export default function MobileSidebar({ handleClick }) {
    const { logout } = useLogout()
  return (
    <div
     className='mobile-sidebar-container'
     onClick={() => handleClick()}
     >
          <motion.div 
          className='mobile-sidebar-component-container'
          initial={{ x:'-100vw' }}
          animate={{ x:0, transition:{
            duration: 0.5,
            type: 'tween'
          } }}
          >
      <div className='sidebar-component-wrapper'>
        <div className='sidebar-menu-list-container'>
            <div className='sidebar-menu-list-wrapper'>
                <div
                 className='sidebar-menu-list1'
                 onClick={() => handleClick()}
                >
                <li className=''>
                    <NavLink to='/dashboard' className='link sidebar-list-item'>
                        <MdSpaceDashboard className='sidebar-icon'/>
                        <span>Dashboard</span>
                    </NavLink>
                    </li>
                    <li className=''> 
                    <NavLink to='/users' className='link sidebar-list-item'>
                        <FaUsers className='sidebar-icon'/>
                        <span>Users</span>
                    </NavLink> 
                    </li>
                    <li className=''> 
                    <NavLink to='/properties' className='link sidebar-list-item'>
                        <HiMiniHomeModern className='sidebar-icon'/>
                        <span>Properties</span>
                    </NavLink> 
                    </li>
                    <li className=''> 
                    <NavLink to='/premium_ads' className='link sidebar-list-item'>
                        <SiHomebridge className='sidebar-icon'/>
                        <span>Premium Ads</span>
                    </NavLink> 
                    </li>
                    <li className=''>
                        <NavLink to='/guides' className='link sidebar-list-item'>
                        <MdOutlineBook className='sidebar-icon'/>
                        <span>Guides</span>
                        </NavLink>
                    </li>
                    <li className=''>
                        <NavLink to='/tickets' className='link sidebar-list-item'>
                        <AiFillBook className='sidebar-icon'/>
                        <span>Tickets</span>
                        </NavLink> 
                    </li>
                    <li className=''> 
                    <NavLink to='/admin_users' className='link sidebar-list-item'>
                        <FaUsersGear className='sidebar-icon'/>
                        <span>Admin Users</span>
                    </NavLink>
                    </li>
                    {/* <li className=''> 
                    <NavLink to='/settings' className='link sidebar-list-item'>
                        <FiSettings className='sidebar-icon'/>
                        <span>Settings</span>
                    </NavLink>
                    </li> */}
                </div>
            </div>
        </div>
        <div className='sidebar-menu-list2-container'>
            <div className='sidebar-menu-list-wrapper'>
                <div
                 className='sidebar-menu-list2'
                 onClick={() => handleClick()}
                >                  
                    <li className='sidebar-list-item logout-button'
                        onClick={() => logout()}
                    > 
                        <FiLogOut className='sidebar-icon'/>
                        <span>LogOut</span>
                    </li>                   
                </div>
            </div>
        </div>
      </div>
    </motion.div>
    </div>
  )
}
