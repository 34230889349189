import TableComponents from "./components"
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button';
import { FaUser } from "react-icons/fa";
import { HiOutlineHomeModern } from "react-icons/hi2";
import { useDeleteDoc } from "../../hooks/useDeleteDoc";
import { PreLoader } from "../../utils/preloader";
import { useEffect, useState } from "react";
import { serverOne } from "../../helpers/server";
import axios from 'axios'
import './styles.css'
import { Success } from "../../utils/marker/success";
import { ErrorMarker } from "../../utils/marker/error";

export const UserList = () => {
    // const { getUsers, loading, result } = UseGetUsers()
    const [ loading, setLoading ] = useState(false);
    const [ result, setResult ] = useState([]);
    const [ totalUsers, setTotalUsers ] = useState(0)
    const [ totalLandlords, setLandLords ] = useState(0)
    const { dLoading, dError, dErrorMessage, dSuccess, dSuccessMessage, deleteDoc } = useDeleteDoc()

    useEffect(() => {
        console.log('hello')
        const getUsers = async() => {
            try{
                console.log({serverOne})
                setLoading(true)
                const response = await axios({
                    method: 'GET',
                    url: `${serverOne}/users`
                })
                console.log(response.data)
                if(response.data.status !== 'Success'){
                    setLoading(false)
                }
                setLoading(false)
                setResult(response.data.doc)
                setTotalUsers(response.data.result)
                const docs = await response.data.doc
                const landlords = docs.filter(el => el.userType === 'landlord')
                setLandLords(landlords.length)
            } catch(error){
                console.log(error)
                setLoading(false)
            }
        }
        getUsers()
      }, [])
    return (
        <div className="user-list-container page">
            {!loading && <div className="user-list-wrapper">
            <div className="premiumAds-page-box-container">
                        <div className="premium-page-box">
                            <div className="premium-page-box-text">
                                <h2>{totalUsers}</h2>
                                <p>Total Users</p>
                            </div>
                            <div className="premium-page-box-icon-con">
                                <FaUser className="premium-page-box-icon"/>
                            </div>
                        </div>
                        <div className="premium-page-box">
                            <div className="premium-page-box-text">
                                <h2>{totalLandlords}</h2>
                                <p>Total Landlords</p>
                            </div>
                            <div className="premium-page-box-icon-con">
                                <HiOutlineHomeModern className="premium-page-box-icon"/>
                            </div>
                        </div>
                    </div>
                <div className="user-list-search-input-container">
                <TextField
                    id="outlined-basic" 
                    label="Search For User" 
                    variant="outlined" 
                    // style={{ height: '35px' }}
                />
                <Button variant="contained">Search</Button>
                </div>
                <TableComponents result={result} deleteDoc={deleteDoc}/>
            </div>}
            { (loading || dLoading) && <PreLoader/> }
            { dSuccess && <Success text={dSuccessMessage}/> }
            { dError && <ErrorMarker text={dErrorMessage}/> }
            {/* <PreLoader/> */}
        </div>
    )
}