import './styles.css'
import { useState } from 'react';
import { MdCancel } from "react-icons/md";

export const CreateAdmin = ({ remove, createAdmin }) => {
    const [ firstName, setFirstName ] = useState('')
    const [ firstNameError, setFirstNameError ] = useState('')
    const [ middleName, setMiddleName ] = useState('')
    const [ middleNameError, setMiddleNameError ] = useState('')
    const [ lastName, setLastName ] = useState('')
    const [ lastNameError, setLastNameError ] = useState('')
    const [ email, setEmail ] = useState('')
    const [ emailError, setEmailError ] = useState('')
    const [ phone, setPhone ] = useState('')
    const [ phoneError, setPhoneError ] = useState('')
    const [ password, setPassword ] = useState('')
    const [ passwordError, setPasswordError ] = useState('')

    const handelCreateAdmin = () => {
        setFirstNameError('')
        setLastNameError('')
        setMiddleNameError('')
        setEmailError('')
        setPhoneError('')
        setPasswordError('')
        if(!firstName) setFirstNameError('First Name is missing')
        if(!middleName) setMiddleNameError('Middle Name is missing')
        if(!lastName) setLastNameError('Last Name is missing')
        if(!email) setEmailError('Email is missing')
        if(!phone) setPhoneError('Phone is missing')
        if(!password) setPasswordError('password is missing')

        if(firstName && lastName && middleName && email && phone && password){
            createAdmin(
                firstName,
                lastName,
                middleName,
                email,
                password,
                phone
            )
        }
    }

    return (
        <div className="create-admin-pop-container page">
            <div className="create-admin-pop-wrapper">
                <div className="create-admin-form">
                    <div className='create-admin-top-con'>
                        <h4>Create Admin</h4>
                        <div className='rm-icon'
                            onClick={() => remove()}
                        >
                            <MdCancel/>
                        </div>
                    </div>
                    <label>
                        <span>FirstName</span>
                        <input type="text"
                            onChange={e => setFirstName(e.target.value)}
                            value={firstName}
                        />
                        { firstNameError && <div className='error'>{firstNameError}</div> }
                    </label>
                    <label>
                        <span>Middle Name</span>
                        <input type="text" onChange={e => setMiddleName(e.target.value)}/>
                        { middleNameError && <div className='error'>{middleNameError}</div>}
                    </label>
                    <label>
                        <span>Last Name</span>
                        <input type="text" 
                            onChange={e => setLastName(e.target.value)}
                        />
                        { lastNameError && <div className='error'>{lastNameError}</div> }
                    </label>
                    <label>
                        <span>Email</span>
                        <input type="text"
                            onChange={e => setEmail(e.target.value)}
                            value={email}
                        />
                        {emailError && <div className='error'>{emailError}</div>}
                    </label>
                    <label>
                        <span>Phone</span>
                        <input type="text"
                            onChange={e => setPhone(e.target.value)}
                            value={phone}
                        />
                        { phoneError && <div className='error'>{phoneError}</div> }
                    </label>
                    <label>
                        <span>Password</span>
                        <div className="password-generator">
                            <input type="text"
                                onChange={e => setPassword(e.target.value)}
                                value={password}
                            />
                            <button>Generate</button>
                        </div>
                        { passwordError && <div className='error'>{passwordError}</div> }
                    </label>
                    <div className="create-admin-button-container">
                        <button onClick={() => handelCreateAdmin()}>Create account</button>
                    </div>
                </div>
            </div>
        </div>
    )
}