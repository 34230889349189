import './styles.css'
import { useRef } from 'react'

export const SideBar = ({ activities, security }) => {

    const profileRef = useRef()
    const securityRef = useRef()
    const billingRef = useRef()
    const activitiesRef = useRef()

    const pRef = () => {
        profileRef.current.classList.add('active')
        securityRef.current.classList.remove('active')
        billingRef.current.classList.remove('active')
        activitiesRef.current.classList.remove('active')
    }
    const sRef = () => {
        securityRef.current.classList.add('active')
        profileRef.current.classList.remove('active')
        billingRef.current.classList.remove('active')
        activitiesRef.current.classList.remove('active')
    }
    const bRef = () => {
        billingRef.current.classList.add('active')
        profileRef.current.classList.remove('active')
        securityRef.current.classList.remove('active')
        activitiesRef.current.classList.remove('active')
    }
    const aRef = () => {
        activitiesRef.current.classList.add('active')
        profileRef.current.classList.remove('active')
        securityRef.current.classList.remove('active')
        billingRef.current.classList.remove('active')
    }

    return (
        <div className="settings-sidebar-container">
            <div className="settings-sidebar-wrapper">
                <div className="settings-sidebar-list">
                    <div className="settings-sidebar-list-item active"
                     ref={profileRef}
                     onClick={() => {
                        pRef()
                    }}
                    >
                        <h4>Profile</h4>
                    </div>
                    <div className="settings-sidebar-list-item" ref={securityRef} 
                    onClick={() => {
                        sRef()
                        security()
                        }}>
                        <h4>Security</h4>
                    </div>
                    <div className="settings-sidebar-list-item" ref={billingRef} onClick={() => {
                        bRef()
                    }}>
                        <h4>Billing</h4>
                    </div>
                    <div className="settings-sidebar-list-item" ref={activitiesRef}
                     onClick={() => {
                        aRef()
                        activities()
                     }}
                    >
                        <h4>Activities</h4>
                    </div>
                </div>
            </div>
        </div>
    )
}