import { useState } from "react"
import axios from 'axios'
import { serverOne } from "../../../helpers/server"

export const UseGetUsers = () => {
    const [ isLoading, setIsLoading ] = useState(false);
    const [ result, setResult ] = useState([]);
    const [ totalUsers, setTotalUsers ] = useState(0)
    const [ totalLandlords, setLandLords ] = useState(0)

    const getUsers = async() => {
        try{
            console.log({serverOne})
            setIsLoading(true)
            const response = await axios({
                method: 'GET',
                url: `${serverOne}/users`
            })
            console.log(response.data)
            if(response.data.status !== 'Success'){
                setIsLoading(false)
            }
            setIsLoading(false)
            setResult(response.data.doc)
            setTotalUsers(response.data.result)
            const docs = await response.data.doc
            const landlords = docs.filter(el => el.userType === 'landlord')
            setLandLords(landlords.length)
        } catch(error){
            console.log(error)
            setIsLoading(false)
        }
    };
    return { isLoading, getUsers, result, totalLandlords, totalUsers }
}