import RentList from './components'
import './styles.css'

const rentList = [
    { sn:1, name: 'The new House', user: 'John Doe', date: '20-11-23' },
    { sn:2, name: 'The new House', user: 'John Doe', date: '20-11-23' },
    { sn:3, name: 'The new House', user: 'John Doe', date: '20-11-23' },
    { sn:4, name: 'The new House', user: 'John Doe', date: '20-11-23' },
    { sn:5, name: 'The new House', user: 'John Doe', date: '20-11-23' },
    { sn:6, name: 'The new House', user: 'John Doe', date: '20-11-23' }
]
export const Rents = () => {
    const hello = () => {
        console.log('Hello There')
    }
    return (
        <div className="rent-page-container page">
            <div className="rent-page-wrapper">
                <RentList getData={hello} result={rentList}/>
            </div>
        </div>
    )
}