import { useState } from 'react';
import axios from 'axios'
import { serverOne } from '../../../helpers/server';

export const Analysis = () => {
    const [ loading, setLoading ] = useState(false)
    // const [ result, setResult ] = useState([])
    const [ error, setError ] = useState(false)
    const [ totalProperties, setTotalProperties ] = useState(0)
    const [ totalUsers, setTotalUsers ] = useState(0)
    const [ totalLords, setTotalLandLords ] = useState(0)
    const [ totalTenants, setTotalTenants ] = useState(0)
    const [ totalRents, setTotalRents ] = useState(0)

    const query = async (endpoint) => {
        const response = await axios({
            method: 'GET',
            url: `${serverOne}/${endpoint}`,
        })
        return response;
    };

    const getAnalysis = async () => {
        try{
            setLoading(true)
            setError(false)
            await query('properties')
            .then(res => {
                console.log({res})
                setTotalProperties(res.data.result)
                const properties = res.data.doc
                const pt1 = properties.filter(el => el.property_category === 'rent')
                setTotalRents(pt1.length)
            }).catch(err => console.log(err))
            await query('users')
            .then(res => {
                console.log({res})
                setTotalUsers(res.data.result)
                const users = res.data.doc
                const dt1 = users.filter(el => el.userType === 'landlord')
                const dt2 = users.filter(el => el.userType === 'tenant')
                setTotalLandLords(dt1.length)
                setTotalTenants(dt2.length)
            }).catch(err => console.log(err))
            setLoading(false)
            setError(false)
        } catch(error){
            setError(true)
            setLoading(false)
        }
    }
    return { getAnalysis,error, loading, totalProperties, totalLords, totalTenants, totalUsers, totalRents}
};