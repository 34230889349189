import './styles.css'
import TableComponents from './components/table'
import { Link } from 'react-router-dom'
import './styles.css'
import { FaUser } from "react-icons/fa"
import { MdOutlineLibraryBooks } from "react-icons/md";
import { useGetGuides } from './hooks/useGetGuides';
import { PreLoader } from '../../utils/preloader';
import { ErrorMarker } from '../../utils/marker/error';
import { Success } from '../../utils/marker/success';
import { useDeleteGuide } from './hooks/useDeleteGuide';

export const Guides = () => {
    const { getGuides, loading, result, totalGuide, totalAuthors } = useGetGuides()
    const { isLoading, error, errorMessage, success, deleteGuide } = useDeleteGuide()
    return (
        <div className="premiumAds-page-container page">
            <div className="premiumAds-page-wrapper">
                <div className="premiumAds-page-first-container">
                    <div className="premiumAds-page-text">
                        <h3>Guide List</h3>
                        <div className="guide-page-button-container">
                            <Link to='/guides/new'>
                                <button>Add New</button>
                            </Link>
                        </div>
                    </div>
                    <div className="premiumAds-page-box-container">
                        <div className="premium-page-box">
                            <div className="premium-page-box-text">
                                <h2>{totalGuide}</h2>
                                <p>Total Guides</p>
                            </div>
                            <div className="premium-page-box-icon-con">
                                <MdOutlineLibraryBooks className="premium-page-box-icon"/>
                            </div>
                        </div>
                        <div className="premium-page-box">
                            <div className="premium-page-box-text">
                                <h2>{totalAuthors}</h2>
                                <p>Total Authors</p>
                            </div>
                            <div className="premium-page-box-icon-con">
                                <FaUser className="premium-page-box-icon"/>
                            </div>
                        </div>
                    </div>
                </div>
                <TableComponents getData={getGuides} result={result} deleteGuide={deleteGuide} />
            </div>
            { (loading || isLoading) && <PreLoader/> }
            { error && <ErrorMarker text={errorMessage}/> }
            { success && <Success/> }
        </div>
    )
}