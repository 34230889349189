import { NavLink } from 'react-router-dom'
// style sheet
import './styles.css'
// react icons
import { MdSpaceDashboard } from "react-icons/md";
import { FaUserCircle } from 'react-icons/fa'
import { FaUsers } from "react-icons/fa";
import { FaUsersGear } from "react-icons/fa6";
import { useLogout } from '../../hooks/useLogout';
import { HiMiniHomeModern } from "react-icons/hi2";
import { SiHomebridge } from "react-icons/si";
import { MdOutlineBook } from "react-icons/md";
import { AiFillBook } from "react-icons/ai";

export default function SidebarComponent() {
    const { logout } = useLogout()
    
  return (
    <div className='sidebar-component-container'>
      <div className='sidebar-component-wrapper'>
        {/* <div className='logo-container sidebar-logo-container'>
            <Link to='/' className='logo-link-container'>
                <div className='logo-image-container'>
                    <img src='	https://www.imbapano.com/imalo/imalo/assets/images/logo.png' alt='tester'/>
                </div>
            </Link>
            </div> */}
        <div className='sidebar-menu-list-container'>
            <div className='sidebar-menu-list-wrapper'>
                <div className='sidebar-menu-list1'>
                    <li className=''>
                    <NavLink to='/dashboard' className='link sidebar-list-item'>
                        <MdSpaceDashboard className='sidebar-icon'/>
                        <span>Dashboard</span>
                    </NavLink>
                    </li>
                    <li className=''> 
                    <NavLink to='/users' className='link sidebar-list-item'>
                        <FaUsers className='sidebar-icon'/>
                        <span>Users</span>
                    </NavLink> 
                    </li>
                    <li className=''> 
                    <NavLink to='/properties' className='link sidebar-list-item'>
                        <HiMiniHomeModern className='sidebar-icon'/>
                        <span>Properties</span>
                    </NavLink> 
                    </li>
                    <li className=''> 
                    <NavLink to='/premium_ads' className='link sidebar-list-item'>
                        <SiHomebridge className='sidebar-icon'/>
                        <span>Premium Ads</span>
                    </NavLink> 
                    </li>
                    <li className=''>
                        <NavLink to='/guides' className='link sidebar-list-item'>
                        <MdOutlineBook className='sidebar-icon'/>
                        <span>Guides</span>
                        </NavLink>
                    </li>
                    <li className=''>
                        <NavLink to='/tickets' className='link sidebar-list-item'>
                        <AiFillBook className='sidebar-icon'/>
                        <span>Tickets</span>
                        </NavLink> 
                    </li>
                    <li className=''> 
                    <NavLink to='/admin_users' className='link sidebar-list-item'>
                        <FaUsersGear className='sidebar-icon'/>
                        <span>Admin Users</span>
                    </NavLink>
                    </li>
                    {/* <li className=''> 
                    <NavLink to='/settings' className='link sidebar-list-item'>
                        <FiSettings className='sidebar-icon'/>
                        <span>Settings</span>
                    </NavLink>
                    </li> */}
                </div>
            </div>
        </div>
        <div className='sidebar-menu-list2-container'>
            <div className='sidebar-menu-list-wrapper'>
                <div className='sidebar-menu-list2'>                  
                    <li className='sidebar-list-item logout-button' 
                        onClick={() => logout()}
                    > 
                     <span>LogOut</span>
                    </li>                 
                </div>
            </div>
        </div>
      </div>
    </div>
  )
}
