import * as React from 'react';
import Popover from '@mui/material/Popover';
import { SlOptionsVertical } from "react-icons/sl";
import { FaTrash } from "react-icons/fa";
import { LiaEdit } from "react-icons/lia";
import { useNavigate } from 'react-router-dom'

export default function BasicPopover({ ID, deleteGuide }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate()

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNavigate = () => {
    navigate(`/guides/${ID}`)
  }

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <SlOptionsVertical aria-describedby={id} variant="contained" onClick={handleClick} style={{ cursor:'pointer' }}/>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'middle',
          horizontal: 'right',
        }}
      >
        <div className="popover-container">
            <div
             className="pop-button-action pop-view-button"
             onClick={() => handleNavigate()}
            >
                <LiaEdit/> <span>View</span>
            </div>
            <div className="pop-button-action pop-delete-button"
              onClick={() => deleteGuide(ID)}
            >
                <FaTrash/> <span>Delete</span>
            </div>
        </div>
      </Popover>
    </div>
  );
}