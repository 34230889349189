import './styles.css'
import { useState, useRef } from 'react'
import Image from '../../assets/2f.png'
import { UseLogin } from '../../hooks/useLogin'


export const Login = () => {
    const [ email, setEmail ] = useState('')
    const [ emailError, setEmailError ] = useState('')
    const [ password, setPassword ] = useState('')
    const [ passwordError, setPasswordError ] = useState('')
    const { login, error, loading } = UseLogin()

    const passwordRef = useRef()

    const handleShowPassword = () => {
        if(passwordRef.current.type === 'password'){
            passwordRef.current.type = 'text'
        } else{
            passwordRef.current.type = 'password'
        }
        
    }

    const handleLogin = () => {
        setEmailError('')
        setPasswordError('')
        if(!email) setEmailError('Email is required')
        if(!password) setPasswordError('Password is required')

        if(email && password){
            login(email, password )
        }
    }

    return (
        <div className="auth-page-container">
            <div className="auth-page-wrapper">
                <div className="auth-page-image-slide">
                    <div className="auth-page-image-container">
                        <img src={Image} alt="test" />
                    </div>
                    
                </div>
                <div className="auth-form-container">
                <div className="auth-form-logo">
                    <img src="	https://www.imbapano.com/imalo/imalo/assets/images/logo.png" alt="test" />
                </div>
                    <div className="auth-form-text">
                        <h2>Login as Admin</h2>
                    </div>
                    { error && <div className='error'>{error}</div> }
                    <div className="auth-form">
                        <label>
                            <span>Email</span>
                            <input
                             type="Email"
                             placeholder='Enter Email'
                             onChange={e => setEmail(e.target.value)}
                             value={email}
                             />
                            {emailError && <div className='input-error'>{emailError}</div>}
                        </label>
                        <label>
                            <span>Password</span>
                            <input
                             type="password"
                             onChange={e => setPassword(e.target.value)}
                             value={password}
                             ref={passwordRef}
                             placeholder='Enter Password'
                            />
                            { passwordError && <div className='input-error'>{passwordError}</div> }
                        </label>
                        <div className="show-password-container">
                            <input type="checkbox" 
                            onChange={() => handleShowPassword()}
                            className='password-checkbox'/>
                            <p>Show password</p>
                        </div>
                        <div className="auth-button-container">
                            {!loading && <button 
                                onClick={() => handleLogin()}
                            >Login</button>}
                            {loading && <button 
                            >Loading...</button>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}