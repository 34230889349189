import { useAuthContext } from "./useAuthContext";
import cookies from 'js-cookies'

export const useLogout = () => {
    const { dispatch } = useAuthContext()
    const logout = async() => {
        try{
            cookies.removeItem('jwt')
            dispatch({ type: 'LOGOUT' })
        } catch(error){
            console.log('Error:', error)
        }
    }
    return { logout }
}