import axios from 'axios';
import { useState } from 'react';
import { serverOne } from '../helpers/server';

export const useGetData = () => {
    const [ isLoading, setIsLoading ] = useState(false)
    const [ isError, setIsError ] = useState(false)
    const [ success, setSuccess ] = useState(false)
    const [ result, setResult ] = useState([])

    const getData = async(endpoint) => {
        try{
            setIsLoading(true)
            setIsError(false)
            setSuccess(false)
            const response = await axios({
                method: "GET",
                url: `${serverOne}/${endpoint}`
            });
            console.log(response)
            if(response.data.status !== 'Success'){
                setIsLoading(false)
                setIsError(true)
                console.log(response.data)
                setSuccess(false)
            }
            setIsLoading(false)
            setIsError(false)
            setSuccess(false)
            setResult(response.data.doc)
        } catch(error){
            setIsError(true)
            setIsLoading(false)
            setSuccess(false)
        }
    };
    return { getData, isLoading, isError, success, result }
}