import { useAuthContext } from "./useAuthContext";
import { useState } from "react";
import axios from 'axios'
import { serverOne } from "../helpers/server";
import cookies from 'js-cookies';

export const UseLogin = () => {
    const { dispatch } = useAuthContext()
    const [ loading, setLoading ] = useState(false)
    const [ error, setError ] = useState('')
    const login = async(email, password) => {
        try{
            setLoading(true)
            const response = await axios({
                method: "POST",
                url: `${serverOne}/auth/login`,
                data:{
                    email,
                    password
                }
            })
            console.log(response)
            if(response.data.status !== 'Success'){
                setLoading(false)
                setError(response.message)
            }
            setLoading(false)
            console.log(response.data.doc)
            const token = response.data.doc.token;   
            console.log({token})
            cookies.setItem('jwt',token)
            dispatch({ type:'LOGIN', payload: response.data.doc[0] })
            setLoading(false)
        } catch(error){
            console.log(error)
            setLoading(false)
            setError(error.response.data.message)
        }
    }

    return { login, error, loading }
}