import './styles.css'
import { useParams } from 'react-router-dom'
import { useState, useEffect } from 'react';
import Select from 'react-select'
import axios from 'axios'
import { useUpdateGuide } from '../../hooks/useUpdateQuote';
import { serverOne } from '../../../../helpers/server';
import { useCreateGuide } from '../../hooks/useCreateGuide';
import { PreLoader } from '../../../../utils/preloader';
import { Success } from '../../../../utils/marker/success';
import { ErrorMarker } from '../../../../utils/marker/error';
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css';

const categories = [
    { label: 'Rental Guide', value: 'Rental Guide' },
    { label: 'Sales Guide', value: 'Sales Guide' }
]

export const GuidesDetails = () => {
    const { loading, error, errorMessage, success, createGuide } = useCreateGuide()
    const { updateLoading, updateGuide, updateError, updateErrorMessage, updateSuccess } = useUpdateGuide()
    /* const { isLoading, getGuide, result, isError, getErrorMessage } = useGetGuide() */

    const [ title, setTitle ] = useState('')
    const [ titleError, setTitleError ] = useState('')
    const [ coverImage, setCoverImage ] = useState('')
    const [ coverImageError, setCoverImageError ] = useState('')
    const [ category, setCategory ] = useState('')
    const [ categoryError, setCategoryError ] = useState('')
    const [ post, setPost ] = useState('')
    const [ postError, setPostError ] = useState('')
    const [ isError, setIsError ] = useState(false);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ getErrorMessage, setGetErrorMessage ] = useState('');
    const [ showUpdate, setShowUpdate ] = useState(false)
    // const [ success, setSuccess ] = useState(false);
    const [ result, setResult ] = useState({})

    const { id } = useParams()

    const  modules  = {
        toolbar: [
            [{ font: [] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["bold", "italic", "underline", "strike"],
            [{ color: [] }, { background: [] }],
            [{ script:  "sub" }, { script:  "super" }],
            ["blockquote", "code-block"],
            [{ list:  "ordered" }, { list:  "bullet" }],
            [{ indent:  "-1" }, { indent:  "+1" }, { align: [] }],
            ["link", "image", "video"]
        ]
    };

    useEffect(() => {
        if(id === 'new'){
            setShowUpdate(false)

        } else{
            const getGuide = async () => {
                try{
                    setIsLoading(false)
                    setIsError(false)
                    setGetErrorMessage('')
                    const response = await axios({
                        method: 'GET',
                        url: `${serverOne}/guides/${id}`
                    });
                    console.log(response)
                    if(response.data.status !== 'Success'){
                        setIsLoading(false)
                        setIsError(true)
                        setGetErrorMessage('Could not complete action')
                        console.log(response.doc)
                    };
                    setIsError(false)
                    setIsLoading(false)
                    setGetErrorMessage('')
                    setResult(response.data.doc[0])
                    setTitle(response.data.doc[0].title)
                    setPost(response.data.doc[0].content)
                    setCategory(response.data.doc[0].type)
                    setCoverImage(response.data.doc[0].cover_image)
                    console.log(response.data.doc[0])
                    setShowUpdate(true)
                } catch(error){
                    console.log(error)
                    setGetErrorMessage(error.response.data.message)
                    setIsError(true)
                    setIsLoading(false)
                }
            };
            getGuide()
            console.log({result})
        }
    }, [])

    const handleChange = (value) => {
        setPost(value)
    }

    const handleImageChange = async (e) => {
        try{
            setCoverImage(e.target.files[0])
        } catch(error){
        
        }
    }

    const createPost = () => {
        setTitleError('')
        setPostError('')
        setCategoryError('')
        setCoverImageError('')
        if(!title) setTitleError('title is required')
        if(!coverImage) setCoverImageError('Cover Image is required')
        if(!category) setCategoryError('select a category')
        if(!post) setPostError('Content is required ')

        if(title && coverImage && category && post){
            createGuide(
                title,
                post,
                coverImage,
                category,
            )
        }
    }
    
    const handelPost = () => {
        if(id === 'new'){
            createPost()
        }else{
            console.log('Doc Updated')
        }
    }

    const updatePost = () => {
        updateGuide(
            id,
            {
                content: post,
                title
            })
    } 

    return (
        <div className="guide-details-container page">
            <div className="guide-details-wrapper">
                <div className="guide-details-text">
                    <h4>Post a Guide</h4>
                </div>
                <label>
                    <span>Title</span>
                    <input 
                        type="text" 
                        placeholder="Enter Guide Title" 
                        onChange={e => setTitle(e.target.value)}
                        value={title}
                    />
                    { titleError && <div className='error'>{titleError}</div> }
                </label>
                <div className="guide-details-sub-container">
                    <label>
                        <span>Cover Image</span>
                        <div className="guide-detail-file-container">
                            <input
                                type="file" 
                                placeholder="Select an image" 
                                onChange={handleImageChange}
                                // value={coverImage}
                            />
                            { coverImageError && <div className='error'>{coverImageError}</div> }
                        </div>
                    </label>
                    <label>
                        <span>Category</span>
                        <Select
                            options={categories}
                            onChange={option => setCategory(option.value)}
                            // value={option}
                        />
                        {categoryError && <div className='error'>{categoryError}</div> }
                    </label>
                </div>
                <div className="guide-quill-editor-container">
                {postError && <div className='error'>{postError}</div> }
                    <ReactQuill
                        modules={modules}
                        theme='snow'
                        className='guide-note-textarea'
                        placeholder='Start a post'
                        onChange={handleChange}
                        value={post}
                    />
                    
                </div>
                <div className="guide-details-button-container">
                    {!showUpdate &&<button onClick={() => handelPost()}>Post</button>}
                    {showUpdate &&<button onClick={() => updatePost()}>Update</button>}
                </div>
            </div>
            {( success || updateSuccess) && <Success/> }
            { error && <ErrorMarker text={errorMessage}/> }
            { isError && <ErrorMarker text={getErrorMessage}/> }
            { updateError && <ErrorMarker text={updateErrorMessage}/> }
            { (loading || isLoading || updateLoading) && <PreLoader/> }
        </div>
    )
}